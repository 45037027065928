/* eslint-disable */
import Konva from "konva";
import YNode  from "./YNode";

export default class YNodeDefault  extends YNode {
    
    constructor(id = null) {
        super();

        if (id) {
            this._id = id;
        }
    }

    make() {
        var maxValue = Math.max(this.num_out, this.num_in);
        if (this.num_out > 1) {
            this.distanceOut = 30;
        }
        if (this.num_in > 1) {
            this.distanceIn = 30;
        }
        this.heightDefault *= maxValue;

        if(this.style.icon && this.style.label) {
            var icon = new Konva.Text({
                x: 10,
                y: 8,
                padding: 10,
                align: 'center',
                width: 90,
                height: 40,
                text: this.style.icon,
                fontSize: 25,
                fontFamily: 'Material Symbols Outlined',
                fill: '#252525',
            });
            icon.listening(false);
            var title = new Konva.Text({
                x: icon.getAttr('x') + 65,
                y: 20,
                text: this.style.label,
                fontSize: 18,
                fontFamily: 'Calibri',
                fill: '#252525',
            });
            title.listening(false);
            if (title.getAttr('width') > 10) {
                this.widthDefault = title.getAttr('width') + 130;
            }

            this.add(title);
            this.add(icon);
        }

        this.render();
    }
}