/* eslint-disable */
import Konva from "konva";
import YNode  from "./YNode";

export default class YNodeWelcome extends YNode {
    constructor(id = null) {
        super();

        if (id) {
            this._id = id;
        }

        this.widthDefault = 200;
        this.heightDefault = 150;
    }

    make() {
        var title = new Konva.Text({
            x: this.widthDefault / 2 - 40,
            y: this.heightDefault / 2 + 20,
            text: this.style.label,
            fontSize: 18,
            fontFamily: 'Calibri',
            fontStyle: 'bold',
            fill: '#252525',
        });
        title.listening(false);
        this.add(title);

        var SOURCE = this.style.source;
        Konva.Image.fromURL(SOURCE, (imageNode) => {
            this.add(imageNode);
            imageNode.setAttrs({
                width: 40,
                height: 40,
                x: this.widthDefault / 2 - 20,
                y: this.heightDefault / 2 - 40,
            });
        });

        this.render();
    }
}